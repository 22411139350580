import Head from "next/head";

const Meta = ({ title, keywords, description,url }) => {
  return (
    <div>
      <Head>
        <title>{title} || 헬리시움</title>
        <link rel="icon" href="/Heliseum-H-version.png" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={`${title} || 헬리시움`} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/Heliseum-H-version.png" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={`${title} || 헬리시움`} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  // title: "허브",
  keywords: "메이플스토리, maplestory, 넥슨, nexon, 헬리시움, 헬리시움 허브, heliseum, kms, maplestory inven, 메이플인벤, 김창섭",
  url:"hub.heliseum.com"
  // description: "메이플스토리 플레이에 도움되는 유틸리티를 모아놓은 허브입니다."
};

export default Meta;